.footerContainer {
    background: #040404;
    color: #ffffff;
    font-family: Arial, sans-serif;
  }
  
  .mainContent {
    max-width: 1100px;
    padding: 15px 22px;
    margin: 0 auto;
    display: flex;
    gap: 50px;
  }
  
  .companySection {
    flex: 0 0 300px;
  }
  
  .logo {
    font-size: 24px;
    margin-bottom: 15px;
    color: #ffffff;
  }
  
  .tagline {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 10px;
    opacity: 0.8;
  }
  
  .linkRel {
    font-weight: bold;
    transition: all 0.2s ease;
  }
  .linkRel:hover, .linkRel:active {
    opacity: 80%;
  }
.payMethodsContainer {
  display: flex;
  gap: 10px;
  align-items: center;
}
  
  .columnsWrapper {
    display: flex;
    gap: 60px;
    flex-wrap: wrap;
  }
  
  .column {
    flex: 1;
    min-width: 180px;
  }
  
  .columnTitle {
    font-size: 18px;
    margin-bottom: 20px;
    color: #ffffff;
  }
  
  .linkList {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .linkList li {
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    transition: opacity 0.3s;
    align-items: center;
    display: flex;
    gap: 5px;
  }
  
  .linkList li:hover {
    opacity: 0.8;
  }
  
  .cooperationText {
    font-size: 14px;
    line-height: 1.4;
    max-width: 250px;
  }

  @media only screen and (max-width:993px) {
   .columnsWrapper {
    margin-bottom: 70px;
   } 
  }