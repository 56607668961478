.base_profile {
    align-items: center;
    text-decoration: none;
    color: #f1f1f1;
    font-weight: 400;
    line-height: 12.07px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 9px;
    text-align: center;
  }
  
  .footer {
    position: fixed;
    width: 90%;
    height: 63px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 10px;
    padding: 0;
    box-sizing: border-box;
    background: linear-gradient(135deg, #0f0f0f, #060606, #0f0f0f, #111);
    border-radius: 15px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 1000;
    overflow: hidden;
    border: 1px solid #282828;
  }
  
  .bottom-background {
    position: fixed;
    width: 100%;
    height: 12px;
    bottom: -1px;
    left: 0;
    background-color: #000;
    z-index: 999;
  }
  
  .footer-icon {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #333;
    z-index: 100;
  }
  
  .footer-icon img {
    margin-bottom: 13px;
    height: 25px;
  }
  
  .cart-quantity {
    position: absolute;
    top: -9px;
    right: -5px;
    color: #6be316;
    background-color: #121212;
    border-radius: 50%;
  }
  
  @media only screen and (min-width: 993px) and (max-width: 1200px) {
    .footer {
      display: none;
    }
    .bottom-background {
      display: none;
    }
  }
  
  @media only screen and (min-width: 1201px) {
    .footer {
      display: none;
    }
    .bottom-background {
      display: none;
    }
  }